
.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-up {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-down {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fade-in-right {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fade-in-left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
div.hopscotch-bubble .hopscotch-nav-button {
  /* borrowed from katy styles */
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  margin: 0;
  overflow: visible;
  text-decoration: none !important;
  width: auto;
  padding: 0 10px;
  height: 26px;
  line-height: 24px;
  font-size: 12px;
  *zoom: 1;
  white-space: nowrap;
  display: -moz-inline-stack;
  display: inline-block;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* div.hopscotch-bubble .hopscotch-nav-button:hover {
  *zoom: 1;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
} */
/* div.hopscotch-bubble .hopscotch-nav-button:active {
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25) inset;
} */
div.hopscotch-bubble .hopscotch-nav-button.next {
  border-color: #EFDEFF;
  color: #975AFF;
  margin: 0 0 0 10px;
  /* HS specific*/
  /* text-shadow: 0 1px 1px rgba(0, 0, 0, 0.35); */
  background-color: #EFDEFF;
  /* filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#287bbc', endColorstr='#23639a');
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #287bbc), color-stop(100%, #23639a));
  background-image: -webkit-linear-gradient(to bottom, #287bbc 0%, #23639a 100%);
  background-image: -moz-linear-gradient(to bottom, #287bbc 0%, #23639a 100%);
  background-image: -o-linear-gradient(to bottom, #287bbc 0%, #23639a 100%);
  background-image: linear-gradient(to bottom, #287bbc 0%, #23639a 100%); */
}
/* div.hopscotch-bubble .hopscotch-nav-button.next:hover {
  background-color: rgba(0, 0, 0, 0.35);
} */
div.hopscotch-bubble .hopscotch-nav-button.prev {
  border-color: #EFDEFF;
  color: #975AFF;
  /* text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75); */
  background-color: #EFDEFF;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#f2f2f2', endColorstr='#e9e9e9');
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #f2f2f2), color-stop(100%, #e9e9e9));
  background-image: -webkit-linear-gradient(to bottom, #f2f2f2 0%, #e9e9e9 100%);
  background-image: -moz-linear-gradient(to bottom, #f2f2f2 0%, #e9e9e9 100%);
  background-image: -o-linear-gradient(to bottom, #f2f2f2 0%, #e9e9e9 100%);
  background-image: linear-gradient(to bottom, #f2f2f2 0%, #e9e9e9 100%);
}
/* div.hopscotch-bubble .hopscotch-nav-button.prev:hover {
  background-color: #e8e8e8;
  filter: progid:DXImageTransform.Microsoft.gradient(gradientType=0, startColorstr='#FFE8E8E8', endColorstr='#FFA9A9A9');
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #e8e8e8), color-stop(13%, #e3e3e3), color-stop(32%, #d7d7d7), color-stop(71%, #b9b9b9), color-stop(100%, #a9a9a9));
  background-image: -webkit-linear-gradient(to bottom, #e8e8e8 0%, #e3e3e3 13%, #d7d7d7 32%, #b9b9b9 71%, #a9a9a9 100%);
  background-image: -moz-linear-gradient(to bottom, #e8e8e8 0%, #e3e3e3 13%, #d7d7d7 32%, #b9b9b9 71%, #a9a9a9 100%);
  background-image: -o-linear-gradient(to bottom, #e8e8e8 0%, #e3e3e3 13%, #d7d7d7 32%, #b9b9b9 71%, #a9a9a9 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #e3e3e3 13%, #d7d7d7 32%, #b9b9b9 71%, #a9a9a9 100%);
} */
div.hopscotch-bubble {
  background-color: #975AFF;
  border: 2px solid #975AFF;
  /* default */
  border: 2px solid #975AFF;
  /* transparent, if supported */
  color: #fff;
  font-family: Helvetica, Arial;
  font-size: 13px;
  position: absolute;
  z-index: 999999;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -moz-background-clip: padding;
  /* for Mozilla browsers*/
  -webkit-background-clip: padding;
  /* Webkit */
  background-clip: padding-box;
  /*  browsers with full support */
}
div.hopscotch-bubble * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
div.hopscotch-bubble.animate {
  -moz-transition-property: top, left;
  -moz-transition-duration: 1s;
  -moz-transition-timing-function: ease-in-out;
  -ms-transition-property: top, left;
  -ms-transition-duration: 1s;
  -ms-transition-timing-function: ease-in-out;
  -o-transition-property: top, left;
  -o-transition-duration: 1s;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-property: top, left;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-property: top, left;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
}
div.hopscotch-bubble.invisible {
  opacity: 0;
}
div.hopscotch-bubble.hide,
div.hopscotch-bubble .hide,
div.hopscotch-bubble .hide-all {
  display: none;
}
div.hopscotch-bubble h3 {
  color: #fff;
  font-family: Helvetica, Arial;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin: -1px 15px 0 0;
  padding: 0;
}
div.hopscotch-bubble .hopscotch-bubble-container {
  padding: 15px;
  position: relative;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  /* to fix text flickering */
}
div.hopscotch-bubble .hopscotch-content {
  font-family: Helvetica, Arial;
  font-weight: normal;
  line-height: 17px;
  margin: -5px 0 11px;
  padding-top: 8px;
}
div.hopscotch-bubble .hopscotch-bubble-content {
  margin: 0 0 0 40px;
}
div.hopscotch-bubble.no-number .hopscotch-bubble-content {
  margin: 0;
}
div.hopscotch-bubble .hopscotch-bubble-close {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  color: #000;
  /* background: transparent url(../img/tour/cross.png) no-repeat; */
  background-size: 8px;
  background-position: 8px 8px;
  cursor: pointer;
  display: block;
  padding: 8px;
  position: absolute;
  text-decoration: none;
  text-indent: -9999px;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
}
div.hopscotch-bubble .hopscotch-bubble-close.hide,
div.hopscotch-bubble .hopscotch-bubble-close.hide-all {
  display: none;
}
div.hopscotch-bubble .hopscotch-bubble-number {
  background: #fff;
  color: #000;
  border-radius: 50%;
  display: block;
  float: left;
  font-size: 17px;
  font-weight: bold;
  line-height: 31px;
  text-align: center;
  width: 30px;
  height: 30px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container {
  position: absolute;
  width: 34px;
  height: 34px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container .hopscotch-bubble-arrow,
div.hopscotch-bubble .hopscotch-bubble-arrow-container .hopscotch-bubble-arrow-border {
  width: 0;
  height: 0;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up {
  top: -18px;
  left: 10px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #975AFF;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  position: relative;
  top: -14px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid #000000;
  border-bottom: 17px solid #975AFF;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down {
  bottom: -35px;
  left: 10px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #975AFF;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  position: relative;
  top: -20px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid #000000;
  border-top: 17px solid #975AFF;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left {
  top: 10px;
  left: -18px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  border-bottom: 17px solid transparent;
  border-right: 17px solid #975AFF;
  border-top: 17px solid transparent;
  position: relative;
  left: 4px;
  top: -34px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid #000000;
  border-right: 17px solid #975AFF;
  border-bottom: 17px solid transparent;
  border-top: 17px solid transparent;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right {
  top: 10px;
  right: -35px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-bottom: 17px solid transparent;
  border-left: 17px solid #975AFF;
  border-top: 17px solid transparent;
  position: relative;
  left: -3px;
  top: -34px;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid #000000;
  border-left: 17px solid #975AFF;
  border-bottom: 17px solid transparent;
  border-top: 17px solid transparent;
}
div.hopscotch-bubble .hopscotch-actions {
  margin: 10px 0 0;
  text-align: right;
}
